<template>
    <v-container fluid>
        <PageLayout>
            <template v-slot:side class="pr-0 pt-4 left_filter">
                <v-icon color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                <v-list class="filter_left mt-5">
                    <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('system_filters')}}</v-list-item-title>
                        </template>

                        <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link :class="item.router === $route.name ? 'active' : ''">
                            <v-list-item-title class="cursor-pointer" @click="sendRoute(item.router)" v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list class="filter_left" v-if="Additional.length > 0">
                    <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </template>
            <template>
                <div class="mb-10">
                    <TabsNav />
                    <h1>{{ filter_show ? $t('search_topic') : $t('menu_topics') }}</h1>
                </div>
                <ResizableTable
                    class="main-data-table"
                    :rows="topics"
                    :columns="filteredHeaders.map((item) => {
                        return {
                            ...item,
                            active: true,
                            prop: item.value,
                            label: item.text,
                            width: 'auto',
                            sortable: true
                        }
                    })"
                >
                    <template v-slot:title="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div  v-bind="attrs"
                                      v-on="on" class="cursor-pointer font_weight_600" @click="openAlert(item)">{{ item.title }}</div>
                            </template>
                            <span>{{$t('topic_accept')}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:company.name="{ item }">
                        <div>
                            <v-list v-if="item.company" subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0 cursor_pointer" @click="showCompany(item.company.uuid)">
                                        <img v-if="item.company.photo !== '/img/avatar.png'" :src="item.company.photo" :alt="item.company.name">
                                        <v-icon v-else class="font-primary" size="36">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.company.name" class="cursor-pointer" @click="showCompany(item.company.uuid)"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.company.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>
                    <template v-slot:client.name="{ item }">
                        <div>
                            <v-list v-if="item.client" subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                        <img v-if="item.client.photo !== '/img/avatar.png'" :src="item.client.photo" :alt="item?.client.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item?.client.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.client.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>
                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>
                </ResizableTable>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
                <alert-dialog
                    :dialog="alertOpen"
                    :dialogTitle="$t('are_you_sure_you_want_to_accept_appeal')"
                    :dialogText="''"
                    @alert_closed="handleAlertClosed"
                />
            </template>
        </PageLayout>
    </v-container>
</template>
<script>

    import { mapGetters } from "vuex"
    import AlertDialog from "../components/AlertDialog";
    import TabsNav from "@/components/Leentech/TabsNav.vue";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FilterView from "@/plugins/mixins/FilterView";


    export default {
        name: "Topics",
        components: {
            PageLayout,
            TabsNav,
            AlertDialog
        },
        data() {
            return {
                topic_item: {},
                topic_uuid: null,

                admin: null,
                adminItems: [],
                adminSearching: null,
                loadingAdmins: false,

                topics: [],
                topicItems: [],

                sortBy: "id",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalTopics: 0,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id'],
                },
                loading: false,
                headers: [
                    {
                        text: this.$t('name'),
                        align: "left",
                        value: "title"
                    },
                    {
                        text: this.$t('client'),
                        align: "left",
                        value: "client.name"
                    },
                    {
                        text: this.$t('company'),
                        align: "left",
                        value: "company.name"
                    },
                    {
                        text: this.$t('date_created'),
                        align: "left",
                        value: "created_at"
                    }
                ],
                system_filters: [
                    {'title': this.$t('new_appeals'), 'count': '10', 'router': 'topics'},
                    {'title': this.$t('my_appeals'), 'count': '0', 'router': 's'},
                ],
                Additional: [],
                group1: true,
                group2: true,
                filter_show: false,
                dialogResult: false,
                alertOpen: false,
                itemEdit: {},
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),

            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getTopics();
        },
        watch: {
            options: {
                handler() {
                    this.getTopics()
                },
                deep: false
            }
        },
        methods: {
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass topics' : 'rowMobiClass';
            },

            async getTopics(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDir: sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }


                await this.$http
                    .get("admin/topic", {
                        params: params,
                    })
                    .then(res => {
                        this.topics = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalTopics = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.topics = []
                        this.totalTopics = 0
                        this.$toastr.error(this.$t('failed_to_get_list_topics'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async addAdmin(item) {
                if (confirm(this.$t('are_you_sure_you_want_to_accept_appeal'))) {
                    var _this = this;
                    this.progress = 0
                    this.loading = true
                    var formData = new FormData()
                    if (item.uuid) {
                        formData.append('topic_uuid', item.uuid)
                    }
                    if (this.$auth.check() && this.$auth.user() && this.$auth.user().uuid) {
                        formData.append('admin_uuid', this.$auth.user().uuid)
                    }
                    // Add
                    await this.$http
                        .post('admin/topic_manager', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('manager_has_been_added'))
                            this.$router.push({
                                name: 'topic.message',
                                params: {
                                    id: item.uuid
                                }
                            })
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('manager_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            openAlert(item) {
                this.itemEdit = item
                this.alertOpen = true;
            },
            handleAlertClosed(action) {
                if(action === 'ok'){
                    this.addAdmin(this.itemEdit)
                }
                this.alertOpen = false;
            },
            showCompany(uuid) {
                this.$router.push({
                    name: 'company.show',
                    params: {
                        id: uuid
                    }
                })
            },

        }
    }
</script>
